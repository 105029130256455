<template>
  <el-dialog
    :visible.sync="showFromModal"
    :show-close="false"
    :close-on-click-modal="false"
    style="padding: 0; text-align: left"
    class="dialogue"
  >
    <div class="dialogue__heading">
      <h2>Account Information</h2>
      <div class="dialogue__close" @click="$emit('close')">
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M13.3 10.0006L19.4873 3.81337C20.1709 3.12974 20.1709 2.02141 19.4873 1.33885L18.6624 0.514004C17.9786 -0.169839 16.8703 -0.169839 16.1877 0.514004L10.0006 6.70106L3.81337 0.512722C3.12974 -0.170907 2.02141 -0.170907 1.33885 0.512722L0.512722 1.33756C-0.170907 2.02141 -0.170907 3.12974 0.512722 3.8123L6.70106 10.0006L0.514004 16.1877C-0.169839 16.8715 -0.169839 17.9799 0.514004 18.6624L1.33885 19.4873C2.02247 20.1709 3.13081 20.1709 3.81337 19.4873L10.0006 13.3L16.1877 19.4873C16.8715 20.1709 17.9799 20.1709 18.6624 19.4873L19.4873 18.6624C20.1709 17.9786 20.1709 16.8703 19.4873 16.1877L13.3 10.0006Z"
            fill="#DB353A"
          />
        </svg>
      </div>
    </div>
    <section class="sources">
      <div class="sources__wrapper">
        <div @click="$emit('productDetails')" class="sources__card">
          <div class="sources__card--bottom">
            <div>
              <h4 class="account-feature">Product Name</h4>
              <span class="product-description">
                {{ currentProduct.productName }}</span
              >
            </div>
            <div>
              <h4 class="account-feature">Currency</h4>
              <span class="product-description">{{
                currentProduct.currency
              }}</span>
            </div>
          </div>
          <div class="features-container">
            <h4 class="account-feature">Description</h4>
            <span class="product-description">{{
              currentProduct.description
            }}</span>
          </div>
          <div class="features-container">
            <h4 class="account-feature">Features</h4>
            <ul class="features-list">
              <li v-for="item in currentProduct.features.details" :key="item">
                {{ item.header }}
              </li>
            </ul>
          </div>
          <div class="features-container">
            <h4 class="account-feature">Requirements</h4>
            <ul class="features-list">
              <li
                v-for="item in currentProduct.requirements.details"
                :key="item"
              >
                {{ item.header }}
              </li>
            </ul>
          </div>
        </div>
      </div>
      <button class="btn btn__red btn__group" @click="getStarted">
        <loading v-if="loadingTwo" />
        <span>Get Started</span>
      </button>
    </section>
  </el-dialog>
</template>

<script>
import { mapState } from "vuex";
// import api from "@/api/api.js";
import dayjs from "dayjs";
let utc = require("dayjs/plugin/utc");
dayjs.extend(utc);
export default {
  methods: {
    getStarted() {
      let productData = {
        productData: this.currentProduct,
      };
      this.$store.commit("setProductData", productData);
      this.$router.push("/account-creation");
    },
    generateAccountNumber() {
      // const formattedDOB = dayjs(String(this.user.profileInfo.dateOfBirth))
      //   .utc()
      //   .format();
      // let payload = {
      //   firstName: this.user.profileInfo.fullname,
      //   email: this.user.profileInfo.email,
      //   surName: this.user.profileInfo.lastname,
      //   middleName: "",
      //   productCode: this.currentProduct.productCode,
      //   currencyCode: this.currentProduct.currency,
      //   branch: "",
      //   appId: "",
      //   shortTitle: "",
      //   customerID: String(this.user.profileInfo.customerId),
      //   channelId: "",
      //   mode: "",
      //   residence: this.user.profileInfo.address,
      //   state: "",
      //   bvn: this.user.profileInfo.BVN,
      //   dateOfBirth: formattedDOB,
      //   preferredName: "",
      //   phoneNumber: this.user.profileInfo.phonenumber,
      //   address: this.user.profileInfo.address,
      //   sector: "",
      //   industry: "",
      //   nationality: "",
      //   clientID: "",
      //   childGender: "",
      //   dao: "",
      // };
      // this.loadingTwo = true;
      // api
      //   .accountNumberGenerator(payload)
      //   .then((response) => {
      //     this.loadingTwo = false;
      //     let newAccount = response.data.nuban;
      //     if (newAccount) {
      //       this.$message({
      //         showClose: true,
      //         message: `${newAccount} is your new account number`,
      //         type: "success",
      //         duration: 10000,
      //       });
      //     } else if (response.data.responseText) {
      //       this.$message({
      //         showClose: true,
      //         message: `${response.data.responseText}`,
      //         type: "warning",
      //         duration: 10000,
      //       });
      //     }
      //     this.$router.push("/dashboard-home");
      //     return response;
      //   })
      //   .catch((error) => {
      //     this.loadingTwo = false;
      //     this.$message({
      //       showClose: true,
      //       message: `Error generating account number, try again later!`,
      //       type: "error",
      //       duration: 10000,
      //     });
      //     throw error;
      //   });
    },
  },
  data() {
    return {
      loadingTwo: false,
    };
  },
  created() {},
  computed: mapState(["user", "loading"]),
  props: ["showFromModal", "currentProduct"],
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_form.scss";

.sources__card--top {
  color: #db353a;
}
.account-feature {
  color: #db353a;
  font-size: 16px;
}
.features-list {
  list-style: none;
}
ul li {
  font-size: 13px;
  padding: 0.2em;
  padding-left: 0;
}
.features-container {
  padding-top: 0.5em;
}
.product-description {
  font-size: 13px;
  padding: 0.2em;
  padding-left: 0;
}
.btn {
  width: 100%;
}
</style>
