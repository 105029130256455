<template>
  <section class="transfer-types">
    <h2 class="transfer-types__heading">Type of Accounts</h2>
    <div class="transfer-types__wrapper" v-loading="loading">
      <div
        class="type"
        @click="setProductDetails(product)"
        v-for="(product, index) in products"
        :key="index"
      >
        <div class="type__svg">
          <img
            class="feature__img"
            src="../../assets/images/accounts-icon.svg"
            alt="accounts-icon"
          />
        </div>
        <span class="type__text">
          {{ product.productName }}
        </span>
        <span class="type__text">
          {{ product.currency }}
        </span>
      </div>
    </div>
    <app-product-details
      v-if="showFromModal"
      @productDetails="setProductDetails(product)"
      :showFromModal="showFromModal"
      @close="showFromModal = false"
      :currentProduct="currentProduct"
    ></app-product-details>
  </section>
</template>

<script>
import api from "@/api/api.js";
import { mapState } from "vuex";
import ProductDetails from "@/components/Transfers/Widgets/ProductDetails.vue";
export default {
  components: {
    "app-product-details": ProductDetails
  },
  computed: mapState(["userAccounts", "user"]),
  data() {
    return {
      products: [],
      currentProduct: null,
      showFromModal: false,
      loading: false
    };
  },
  methods: {
    setProductDetails(product) {
      this.currentProduct = product;
      this.showFromModal = true;
      // this.showPinModal = true;
    },
    getProductTypes() {
      api
        .getAvailableProducts()
        .then(res => {
          this.loading = true;
          let responseStatus = res.status;
          // let responseMessage = res.Message;
          if (responseStatus) {
            let availableProducts = res.data;
            this.products = availableProducts;
            this.loading = false;
          }
        })
        .catch(error => {
          this.$message({
            showClose: true,
            message: `Error retrieving products`,
            type: "error",
            duration: 10000
          });
          this.loading = false;
          throw error;
        });
    }
  },
  created() {
    this.loading = true;
    this.getProductTypes();
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_cardsManagement.scss";
.budget-container {
  border: 1px solid #e0e0e0;
  width: 100%;
  min-height: calc(100vh - 21rem);
  border-radius: 4px;
  align-items: center;
  background: #ffffff;
  margin-top: 8px;
}
.header-class {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.categories-selection {
  font-style: normal;
  font-weight: 500;
  font-family: Red Hat Display;
  padding: 2rem;
  padding-bottom: 0;
}
.category-txt {
  font-family: Red Hat Display;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  padding-left: 1rem;
  color: #000000;
}
.budget-description {
  font-style: normal;
  font: size 13px;
  font-family: Red Hat Display;
  font-weight: 400;
}
.total-expenses {
  font-style: normal;
  font-family: Red Hat Display;
  padding: 2rem;
  font-weight: 500;
  padding-top: 0;
  font-size: 18px;
}
.categories-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 2rem;
  padding-top: 0;
  margin-top: 3rem;
}
.categories-container > div {
  background: #ffffff;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 5px;
  width: 30%;
  padding: 2rem 1rem 1rem 1rem;
  margin-bottom: 7px;
}
.budget-overview {
  display: flex;
  margin-left: 2rem;
  margin-top: 2rem;
}
.category-holder {
  display: flex;
  align-items: center;
}
.categories-cards {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  margin-bottom: 5rem;
  margin-right: 0.8rem;
}
.budget-total {
  width: 15%;
  background: #eafff3;
  border: 1px solid #219653;
  box-sizing: border-box;
  border-radius: 5px;
  margin-right: 0.9rem;
  padding: 1rem;
}
.expenses-total {
  background: #ffe8e8;
  border-radius: 5px;
  width: 15%;
  border: 1px solid #bd3e3e;
  box-sizing: border-box;
  padding: 1rem;
}
.budget-card {
  background: #ffffff;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 5px;
  width: 30%;
  height: 8rem;
  padding: 2rem;
  margin-left: 2rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.budget-txt {
  font-family: Red Hat Display;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;

  text-align: center;
}
.budget-status {
  margin-left: 2rem;
  font-family: Red Hat Display;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  display: flex;
  align-items: center;
}
.budgeted {
  font-family: Red Hat Display;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #219653;
}
.expenses {
  color: #eb5757;
  font-family: Red Hat Display;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
}
.amount {
  color: #000000;
  font-family: Red Hat Display;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
}
.radio-class {
  margin: 0 0.4rem 0 0.4rem;
  background: #c4c4c4;
}
.check-class {
  margin-left: auto;
  height: 2rem;
  width: 2rem;
  margin-right: 0.5rem;
}

.find-category {
  font-family: Red Hat Display;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  color: #000000;
}
.add-category {
  font-family: Red Hat Display;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  color: #db353a;
  padding-top: 0.5rem;
  margin-bottom: 0.8rem;
}
.proceed-btn {
  background: #db353a;
  border-color: #db353a;
  color: #ffffff;
  width: 15%;
  height: 4rem;
  text-align: center;
  letter-spacing: 0.05em;
  line-height: 4rem;
  border-radius: 30px;
  font-family: Red Hat Display;
  font-style: normal;
  margin-right: 1.9rem;
}
.total-budgeted-header {
  font-family: Red Hat Display;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #219653;
}
.total-budgeted-value {
  font-family: Red Hat Display;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  color: #219653;
}

.total-expenses-header {
  font-family: Red Hat Display;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #bd3e3e;
}
.total-expenses-value {
  font-family: Red Hat Display;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  color: #bd3e3e;
}
.transfer-types {
  margin-inline: auto;
  width: 90%;
  /* max-width: 35rem; */
  &__heading {
    font-family: Red Hat Display;
    font-style: normal;
    font-weight: bold;
    font-size: 1.8rem;
    line-height: 2.4rem;
    letter-spacing: 0.05em;
    color: #000000;
    text-align: center;
    margin-bottom: 3rem;
  }
  &__wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
    grid-gap: 3rem;
  }
  .type {
    cursor: pointer;
    &:nth-of-type(1) {
      cursor: pointer;
    }
    &:nth-of-type(4) {
      cursor: pointer;
    }
    &__svg {
      background: #ffe4e5;
      padding: 4rem;
      border-radius: 1rem;
      margin-bottom: 2rem;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 80%;
      }
    }
    &__text {
      font-family: Red Hat Display;
      font-style: normal;
      font-weight: bold;
      font-size: 1.8rem;
      line-height: 2.4rem;
      letter-spacing: 0.05em;
      color: #000000;
      text-align: center;
    }
  }
  // 480px
  @media only screen and (max-width: 30em) {
    .type {
      &__svg {
        padding: 3rem;
      }
    }
  }
}
</style>
